// Dependencies
import React, { useContext } from 'react'
import PropTypes from 'prop-types'

// Components
import Form from './Form'

// Styles
import { ContatoWrapper } from './Contato.styled'

// Context & Helpers
import { Context as MicroCopyContext } from '../../context/microcopyContext'
import { getMicroCopy } from '../../helpers/microcopy'

const Contato = () => {
  const { state } = useContext(MicroCopyContext)
  return (
    <div className="content">
      <ContatoWrapper>
        <div className="contact">
          <Form
            {...{
              firstName: getMicroCopy({
                key: `contact.firstName`,
                data: state?.value,
              }),
              firstNameValidation: getMicroCopy({
                key: `contact.firstNameValidation`,
                data: state?.value,
              }),
              lastName: getMicroCopy({
                key: `contact.lastName`,
                data: state?.value,
              }),
              lastNameValidation: getMicroCopy({
                key: `contact.lastNameValidation`,
                data: state?.value,
              }),
              telephone: getMicroCopy({
                key: `contact.telephone`,
                data: state?.value,
              }),
              telephoneValidation: getMicroCopy({
                key: `contact.telephoneValidation`,
                data: state?.value,
              }),
              email: getMicroCopy({ key: `contact.email`, data: state?.value }),
              emailValidation: getMicroCopy({
                key: `contact.emailValidation`,
                data: state?.value,
              }),
              subject: getMicroCopy({
                key: `contact.subject`,
                data: state?.value,
              }),
              subjectValidation: getMicroCopy({
                key: `contact.subjectValidation`,
                data: state?.value,
              }),
              message: getMicroCopy({
                key: `contact.message`,
                data: state?.value,
              }),
              messageValidation: getMicroCopy({
                key: `contact.messageValidation`,
                data: state?.value,
              }),
              sendMessage: getMicroCopy({
                key: `ctas.sendMessage`,
                data: state?.value,
              }),
              successMessage: getMicroCopy({
                key: `contact.successMessage`,
                data: state?.value,
              }),
              failMessage: getMicroCopy({
                key: `contact.failMessage`,
                data: state?.value,
              }),
              fieldsWithErrorMessage: getMicroCopy({
                key: `contact.fieldsWithErrorMessage`,
                data: state?.value,
              }),
            }}
          />
        </div>
      </ContatoWrapper>
    </div>
  )
}

// Components PropTypes
Contato.propTypes = {
  sectionTitle: PropTypes.string,
  sectionCopy: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
}
Contato.defaultProps = {
  sectionTitle: null,
  sectionCopy: null,
}

export default Contato
