// Dependencies
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Helpers
import {
  validateEmail,
  validatePhone,
  validateRequired,
} from '../../helpers/utils'

// Components
import { loadingSVG } from '../../assets/icons'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Form = ({
  firstName,
  firstNameValidation,
  lastName,
  lastNameValidation,
  telephone,
  telephoneValidation,
  email,
  emailValidation,
  subject,
  subjectValidation,
  message,
  messageValidation,
  sendMessage,
  successMessage,
  failMessage,
  fieldsWithErrorMessage,
}) => {
  const formInitialState = {
    firstName: {
      value: '',
      error: false,
      validation: validateRequired,
    },
    lastName: {
      value: '',
      error: false,
      validation: validateRequired,
    },
    email: {
      value: '',
      error: false,
      validation: validateEmail,
    },
    telephone: {
      value: '',
      error: false,
      validation: validatePhone,
    },
    subject: {
      value: '',
      error: false,
      validation: validateRequired,
    },
    message: {
      value: '',
      error: false,
      validation: validateRequired,
    },
  }

  const [form, setForm] = useState(formInitialState)
  const [formStatus, setFormStatus] = useState('')

  const updateForm = (data) => {
    const newForm = { ...form }
    if (newForm[data.field].error) {
      const isValid = newForm[data.field].validation(data.value)
      newForm[data.field].error = !isValid
    }
    newForm[data.field].value = data.value

    setForm(newForm)
  }

  const validateField = (data) => {
    const newForm = { ...form }
    const isValid = newForm[data.field].validation(newForm[data.field].value)
    newForm[data.field].error = !isValid
    setForm(newForm)
  }

  const validateForm = () => {
    const newForm = { ...form }
    let isFormValid = true
    Object.keys(form).forEach((key) => {
      const isValid = newForm[key].validation(newForm[key].value)
      newForm[key].error = !isValid
      if (!isValid) {
        isFormValid = false
      }
    })

    setForm(newForm)
    return isFormValid
  }

  const submitForm = async (e) => {
    e.preventDefault()
    if (formStatus === 'sending') return
    const isFormValid = validateForm()

    if (isFormValid) {
      setFormStatus('sending')
      const sentForm = { ...form }

      try {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'contact',
            ...{
              firstName: sentForm.firstName.value,
              lastName: sentForm.lastName.value,
              email: sentForm.email.value,
              telephone: sentForm.telephone.value,
              subject: sentForm.subject.value,
              message: sentForm.message.value,
            },
          }),
        }).then(() => {
          setFormStatus('success')
          setForm(formInitialState)
        })
      } catch (e) {
        setFormStatus('fail')
      }
    } else {
      setFormStatus('invalid')
      setTimeout(() => {
        setFormStatus('')
      }, 3000)
    }
  }

  return (
    <form>
      <div
        className={'contact__field' + (form.firstName.error ? ' error' : '')}
      >
        <label htmlFor="firstName">{firstName}</label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          value={form.firstName.value}
          onChange={(e) =>
            updateForm({ field: 'firstName', value: e.target.value })
          }
          onBlur={(e) => validateField({ field: 'firstName' })}
        />
        {form.firstName.error && <small>{firstNameValidation}</small>}
      </div>
      <div className={'contact__field' + (form.lastName.error ? ' error' : '')}>
        <label htmlFor="lastName">{lastName}</label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          value={form.lastName.value}
          onChange={(e) =>
            updateForm({ field: 'lastName', value: e.target.value })
          }
          onBlur={(e) => validateField({ field: 'lastName' })}
        />
        {form.lastName.error && <small>{lastNameValidation}</small>}
      </div>
      <div className={'contact__field' + (form.email.error ? ' error' : '')}>
        <label htmlFor="email">{email}</label>
        <input
          type="text"
          name="email"
          id="email"
          value={form.email.value}
          onChange={(e) =>
            updateForm({ field: 'email', value: e.target.value })
          }
          onBlur={(e) => validateField({ field: 'email' })}
        />
        {form.email.error && <small>{emailValidation}</small>}
      </div>
      <div
        className={'contact__field' + (form.telephone.error ? ' error' : '')}
      >
        <label htmlFor="telephone">{telephone}</label>
        <input
          type="text"
          name="telephone"
          id="telephone"
          value={form.telephone.value}
          onChange={(e) =>
            updateForm({ field: 'telephone', value: e.target.value })
          }
          onBlur={(e) => validateField({ field: 'telephone' })}
        />
        {form.telephone.error && <small>{telephoneValidation}</small>}
      </div>
      <div
        className={
          'contact__field fullwidth' + (form.subject.error ? ' error' : '')
        }
      >
        <label htmlFor="subject">{subject}</label>
        <input
          type="text"
          name="subject"
          id="subject"
          value={form.subject.value}
          onChange={(e) =>
            updateForm({ field: 'subject', value: e.target.value })
          }
          onBlur={(e) => validateField({ field: 'subject' })}
        />
        {form.subject.error && <small>{subjectValidation}</small>}
      </div>
      <div
        className={
          'contact__field fullwidth' + (form.message.error ? ' error' : '')
        }
      >
        <label htmlFor="message">{message}</label>
        <textarea
          name="message"
          id="message"
          value={form.message.value}
          onChange={(e) =>
            updateForm({ field: 'message', value: e.target.value })
          }
          onBlur={(e) => validateField({ field: 'message' })}
        />
        {form.message.error && <small>{messageValidation}</small>}
      </div>
      <div className={'contact__field fullwidth ' + formStatus}>
        {formStatus === 'invalid' && <small>{fieldsWithErrorMessage}</small>}
        {formStatus === 'success' && <small>{successMessage}</small>}
        {formStatus === 'fail' && <small>{failMessage}</small>}
        <button className="button" onClick={submitForm}>
          <span>
            {sendMessage}
            {formStatus === 'sending' && (
              <div dangerouslySetInnerHTML={{ __html: loadingSVG }} />
            )}
          </span>
        </button>
      </div>
    </form>
  )
}

// Components PropTypes
Form.propTypes = {
  firstName: PropTypes.string.isRequired,
  firstNameValidation: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  lastNameValidation: PropTypes.string.isRequired,
  telephone: PropTypes.string.isRequired,
  telephoneValidation: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  emailValidation: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  subjectValidation: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  messageValidation: PropTypes.string.isRequired,
  sendMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  failMessage: PropTypes.string.isRequired,
  fieldsWithErrorMessage: PropTypes.string.isRequired,
}

export default Form
