// Dependencies
import styled from 'styled-components'

// Helpers
import { hexToRgb } from '../../helpers/utils'

// Constants
import { colors } from '../../constants/colors'
import {
  workSans,
  type40,
  type20,
  type14,
  type25,
  playfairDisplay,
  type16,
  type18,
} from '../../constants/fonts'
import { sizesMax } from '../../constants/breakpoints'

export const ContatoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% / 11 * 11);

  padding-top: 10px;

  &:not(:last-child) {
    padding-bottom: 30px;
  }

  > div {
    width: 100%;
  }

  .contact {
    margin-top: 0px;

    form {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__field {
      width: calc(50% - 20px);
      display: flex;
      margin: 0 0 20px 0;
      position: relative;
      flex-direction: column;

      &.fullwidth {
        width: 100%;
      }

      label {
        display: block;
        width: 100%;
        ${type20}
        ${playfairDisplay}
        color: ${colors.cream};
        margin: 0 0 20px 0;
        line-height: 1.3;
        border-bottom: solid 1px ${colors.cream};
        padding-bottom: 10px;
        margin-bottom: 5px;
      }

      input,
      textarea {
        display: block;
        width: 100%;
        height: 40px;
        ${type16}
        ${workSans}
        font-weight: bold;
        line-height: 1.3;
        padding: 0 10px;
        border: solid 1px rgba(${hexToRgb(colors.black)}, 1);
        background: ${colors.black};
        outline: none;
        color: ${colors.white};

        &:focus {
          border: solid 1px rgba(${hexToRgb(colors.black)}, 1);
          background: rgba(${hexToRgb(colors.white)}, 1);
          color: ${colors.black};
        }
      }

      textarea {
        height: 100px;
        padding: 10px;
      }

      small {
        display: none;
        color: ${colors.danger};
        ${type14}
        ${playfairDisplay}
        font-style: italic;
        line-height: 1.3;
        margin: 10px 0;
        text-align: right;
      }

      &.error {
        input,
        textarea {
          border: solid 1px rgba(${hexToRgb(colors.danger)}, 1);
          outline: none;

          &:focus {
            border: solid 1px rgba(${hexToRgb(colors.white)}, 1);
            background: lighten(${colors.white}, 70%);
          }
        }
        small {
          display: block;
        }
      }
      &.success,
      &.fail,
      &.invalid {
        small {
          display: block;
          text-align: center;
        }
      }
      &.success small {
        color: ${colors.white};
      }
    }

    button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1023px) {
    .contact {
      &__field {
        width: 100%;
        // margin: 0 0 10px 0;

        label {
          ${type16}
        }

        input,
        textarea {
          ${type14}
        }

        &.error {
          small {
            margin: 5px 0;
          }
        }
      }
    }
  }
`
